import React, { useEffect, useState } from "react";
import { Box, TextField, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "server";

const StockRecord = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [record, setrecord] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    fetchCommandesByDate(date);
  };
  const formatNumber = (number) => {
    return number.toLocaleString();
  };
  const fetchCommandesByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/product/stockRecord-by-date/${date}`
      );
      setrecord(response.data.Record);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponibles");
      setError("Ces données ne sont pas disponibles");
      setLoading(false);
    }
  };
  console.log(record);
  // useEffect to set today's date initially
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format YYYY-MM-DD
    setSelectedDate(formattedDate);
    fetchCommandesByDate(formattedDate); // Fetch data for today's date initially
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Ajouteé le",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "categorie",
      headerName: "Categorie",
      flex: 1,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "stock",
      headerName: "Quantité Ajouteé",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 1,
    },
  ];
  const row = [];

  record &&
    record.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        categorie: item.category,
        desc: item.desc,
        stock: item.stock,
        userName: item.userName,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Historique des stocks" subtitle="Historique des stocks" />
      <br />
      <TextField
        id="date"
        label="Selectionner la Date"
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default StockRecord;
