import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import StatBox from "components/StatBox";
import {
  Email,
  MonetizationOn,
  PointOfSale,
  Traffic,
} from "@mui/icons-material";
import { server } from "server";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // State for pending products
  const [pendingProducts, setPendingProducts] = useState([]);

  // State for daily, monthly, yearly interests and sales
  const [dailyInterest, setDailyInterest] = useState(0);
  const [monthlyInterest, setMonthlyInterest] = useState(0);
  const [yearlyInterest, setYearlyInterest] = useState(0);
  const [dailySales, setDailySales] = useState(0);
  const [monthlySales, setMonthlySales] = useState(0);
  const [yearlySales, setYearlySales] = useState(0);
  const [dailyCash, setDailyCash] = useState(0);
  const [dailyBanks, setDailyBanks] = useState(0);
  const [monthlyCash, setMonthlyCash] = useState(0);
  const [monthlyBanks, setMonthlyBanks] = useState(0);
  // State and handlers for monthly report
  const [selectedDate1, setSelectedDate1] = useState("");
  const [loading1, setLoading1] = useState(false);

  const handleDateChange1 = (event) => {
    setSelectedDate1(event.target.value);
  };

  const handleDownloadPDF1 = async () => {
    const [year, month] = selectedDate1.split("-");
    try {
      setLoading1(true);
      const response = await axios.get(
        `${server}/sells/generate-pdf-month/${year}/${month}`
      );
      const sales = response.data.sales;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport de Ventes du mois de ${month}/${year}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Monthly Sales Report Title
      doc.setFontSize(18);
      doc.text("Rapport des Ventes mensuelles", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Sales Details
      let detailsY = 80;
      sales.forEach((sale, index) => {
        if (detailsY + 120 > doc.internal.pageSize.height) {
          doc.addPage();
          detailsY = 40;
        }
        doc.setFontSize(14);
        doc.text(`Vente ${index + 1}:`, 50, detailsY);
        doc.setFontSize(12);
        doc.text(`Nom Client: ${sale.nomClient}`, 70, detailsY + 10);
        doc.text(`Fait par: ${sale.username}`, 70, detailsY + 20);
        doc.text(
          `Created At: ${new Date(sale.createdAt).toLocaleDateString()}`,
          70,
          detailsY + 30
        );

        // Products
        doc.setFontSize(12);
        doc.text("Products:", 50, detailsY + 40);
        detailsY += 50;

        sale.products.forEach((product, productIndex) => {
          if (detailsY + 80 > doc.internal.pageSize.height) {
            doc.addPage();
            detailsY = 40;
          }
          doc.text(`- Nom du Produit: ${product.productName}`, 60, detailsY);
          doc.text(`  Description: ${product.desc}`, 80, detailsY + 10);
          doc.text(`  Quantité: ${product.quantity}`, 80, detailsY + 20);
          doc.text(`  Prix unitaire: ${product.price} Fbu`, 80, detailsY + 30);
          doc.text(
            `  Prix Total: ${product.ProductTotal} Fbu`,
            80,
            detailsY + 40
          );
          detailsY += 50;
        });
      });

      // Total Summary
      doc.setFontSize(12);
      const totalPrice = sales.reduce((acc, sale) => {
        return (
          acc +
          sale.products.reduce((acc, product) => acc + product.ProductTotal, 0)
        );
      }, 0);
      doc.text(
        `Total: ${totalPrice} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      // Finalize the PDF and trigger download
      doc.save(`Rapport_du_mois_de${month}_${year}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoading1(false);
    } catch (error1) {
      console.error("Error generating PDF:", error1);
      toast.error("Erreur lors de la génération du PDF des ventes mensuelles");
      setLoading1(false);
    }
  };

  // State and handlers for yearly report
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [loadingYear, setLoadingYear] = useState(false);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDownloadPDFYear = async () => {
    try {
      setLoadingYear(true);
      const response = await axios.get(
        `${server}/sells/generate-pdf-year/${selectedYear}`
      );
      const sales = response.data.sales;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport de Ventes annuelle de ${selectedYear}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Monthly Sales Report Title
      doc.setFontSize(18);
      doc.text("Le rapport annuel sur les ventes", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Sales Details
      let detailsY = 80;
      sales.forEach((sale, index) => {
        if (detailsY + 120 > doc.internal.pageSize.height) {
          doc.addPage();
          detailsY = 40;
        }
        doc.setFontSize(14);
        doc.text(`Vente ${index + 1}:`, 50, detailsY);
        doc.setFontSize(12);
        doc.text(`Nom Client: ${sale.nomClient}`, 70, detailsY + 10);
        doc.text(`Fait par: ${sale.username}`, 70, detailsY + 20);
        doc.text(
          `Created At: ${new Date(sale.createdAt).toLocaleDateString()}`,
          70,
          detailsY + 30
        );

        // Products
        doc.setFontSize(12);
        doc.text("Products:", 50, detailsY + 40);
        detailsY += 50;

        sale.products.forEach((product, productIndex) => {
          if (detailsY + 80 > doc.internal.pageSize.height) {
            doc.addPage();
            detailsY = 40;
          }
          doc.text(`- Nom du Produit: ${product.productName}`, 60, detailsY);
          doc.text(`  Description: ${product.desc}`, 80, detailsY + 10);
          doc.text(`  Quantité: ${product.quantity}`, 80, detailsY + 20);
          doc.text(`  Prix unitaire: ${product.price} Fbu`, 80, detailsY + 30);
          doc.text(
            `  Prix Total: ${product.ProductTotal} Fbu`,
            80,
            detailsY + 40
          );
          detailsY += 50;
        });
      });

      // Total Summary
      doc.setFontSize(12);
      const totalPrice = sales.reduce((acc, sale) => {
        return (
          acc +
          sale.products.reduce((acc, product) => acc + product.ProductTotal, 0)
        );
      }, 0);
      doc.text(
        `Total: ${totalPrice} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      // Finalize the PDF and trigger download
      doc.save(`Rapport_de_l_anneé_de_${selectedYear}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setSelectedYear(false);
    } catch (error) {
      toast.error("Erreur lors de la génération du PDF des ventes annuelles");
      setLoadingYear(false);
    }
  };

  // State and handlers for monthly interest report
  const [selectedMonth2, setSelectedMonth2] = useState("");
  const [loading2, setLoading2] = useState(false);

  const handleMonthChange2 = (event) => {
    setSelectedMonth2(event.target.value);
  };

  const handleDownloadPDF2 = async () => {
    const [year, month] = selectedMonth2.split("-");
    try {
      setLoading2(true);
      const response = await axios.get(
        `${server}/sells/generate-monthly-sales-interest-report/${year}/${month}`
      );
      const blob = response.data.dataPDF;
      const doc = new jsPDF();

      // Set document properties (optional)
      doc.setProperties({
        title: `Les Interest du mois de ${month} / ${year}`,
        subject: "les Details des interet",
        author: "Bismos",
      });

      // Company Logo (if applicable)
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20; // Adjust Y position based on logo presence
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 10, businessY + 5);
      doc.text(
        "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
        10,
        businessY + 10
      );

      // Invoice Details
      const invoiceY = businessY + 20;
      doc.setFontSize(16);
      doc.text("les Details :", 15, invoiceY);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );

      // Extract data from the array and display it in the PDF
      const detailsY = invoiceY + 20;
      doc.setFontSize(12);
      blob.forEach((data, index) => {
        const yOffset = detailsY + index * 30;
        doc.text(`Les interet du mois de: ${month}/${year}`, 10, yOffset);
        doc.text(
          `Totaux de Ventes: ${data.totalPriceVente} Fbu`,
          10,
          yOffset + 10
        );
        doc.text(
          `Totaux d'Interet: ${data.totalInterest} Fbu`,
          10,
          yOffset + 20
        );
        doc.text(
          `Totaux de depenses: ${data.totalDepenses} Fbu`,
          10,
          yOffset + 30
        );
      });

      // Save the PDF
      doc.save(`Les_Interest_du_mois_de_${month}_${year}.pdf`);
      toast.success("Le rapport a été téléchargé avec succès");
      setLoading2(false);
    } catch (error) {
      toast.error(
        "Erreur lors de la génération du rapport des intérêts mensuels"
      );
      setLoading2(false);
    }
  };

  // State and handlers for yearly interest report
  const [selectedYear3, setSelectedYear3] = useState(new Date().getFullYear());
  const [loading3, setLoading3] = useState(false);

  const handleYearChange3 = (event) => {
    setSelectedYear3(event.target.value);
  };

  const handleDownloadPDF3 = async () => {
    try {
      setLoading3(true);
      const response = await axios.get(
        `${server}/sells/generate-yearly-sales-interest-report/${selectedYear3}`
      );
      const blob = response.data.dataPDF;
      const doc = new jsPDF();

      // Set document properties (optional)
      doc.setProperties({
        title: `Les Interet de l'anneé de ${selectedYear3}`,
        subject: "les Details des interet",
        author: "Bismos",
      });

      // Company Logo (if applicable)
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20; // Adjust Y position based on logo presence
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 10, businessY + 5);
      doc.text(
        "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
        10,
        businessY + 10
      );

      // Invoice Details
      const invoiceY = businessY + 20;
      doc.setFontSize(16);
      doc.text("les Details: ", 15, invoiceY);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );

      // Extract data from the array and display it in the PDF
      const detailsY = invoiceY + 20;
      doc.setFontSize(12);
      blob.forEach((data, index) => {
        const yOffset = detailsY + index * 30;
        doc.text(`Les interet de l'annee de: ${selectedYear3}`, 10, yOffset);
        doc.text(
          `Totaux de Ventes: ${data.totalPriceVente} Fbu`,
          10,
          yOffset + 10
        );
        doc.text(
          `Totaux d'Interet: ${data.totalInterest} Fbu`,
          10,
          yOffset + 20
        );
        doc.text(
          `Totaux de depenses: ${data.totalDepenses} Fbu`,
          10,
          yOffset + 30
        );
      });

      // Save the PDF
      doc.save(`Les_Interet_de_l'anneé_de_${selectedYear3}.pdf`);
      toast.success("Le rapport a été téléchargé avec succès");
      setLoading3(false);
    } catch (error) {
      toast.error(
        "Erreur lors de la génération du rapport des intérêts annuels"
      );
      setLoading3(false);
    }
  };
  const formatNumber = (number) => {
    return number.toLocaleString();
  };
  useEffect(() => {
    // Fetch initial data
    const fetchData = async () => {
      try {
        // Fetch daily cash
        const dailyCash = await axios.get(`${server}/sales/daily-sales-cash`);
        setDailyCash(dailyCash.data.finalTotal);

        // Fetch daily banks
        const dailyBanks = await axios.get(
          `${server}/sales/daily-sales-others`
        );
        setDailyBanks(dailyBanks.data.totalPrice);

        // Fetch monthly cash
        const monthlyCash = await axios.get(
          `${server}/sales/monthly-sales-cash`
        );
        setMonthlyCash(monthlyCash.data.totalPrice);

        // Fetch monthly banks
        const monthlyBanks = await axios.get(
          `${server}/sales/monthly-sales-others`
        );
        setMonthlyBanks(monthlyBanks.data.totalPrice);

        const res = await axios.get(`${server}/product/pendingProducts`);
        setPendingProducts(res.data);

        const dailySalesResponse = await axios.get(
          `${server}/sells/api/ventes/daily`
        );
        setDailySales(dailySalesResponse.data.total);

        const monthlySalesResponse = await axios.get(
          `${server}/sells/api/ventes/monthly`
        );
        setMonthlySales(monthlySalesResponse.data.total);

        const yearlySalesResponse = await axios.get(
          `${server}/sells/api/ventes/yearly`
        );
        setYearlySales(yearlySalesResponse.data.total);

        const dailyInterestResponse = await axios.get(
          `${server}/sells/interest/daily`
        );
        setDailyInterest(dailyInterestResponse.data.totalInterest);

        const monthlyInterestResponse = await axios.get(
          `${server}/sells/interest/monthly`
        );
        setMonthlyInterest(monthlyInterestResponse.data.totalInterest);

        const yearlyInterestResponse = await axios.get(
          `${server}/sells/interest/yearly`
        );
        setYearlyInterest(yearlyInterestResponse.data.totalInterest);
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors de la récupération des données initiales");
      }
    };

    fetchData();
  }, []);

  //Daily cash
  const [selectedDateDailyCash, setSelectedDateDailyCash] = useState("");
  const [loadingDailyCash, setLoadingDailyCash] = useState(false);
  const [selectedDateDailyOthers, setSelectedDateDailyOthers] = useState("");
  const [loadingDailyOthers, setLoadingDailyOthers] = useState(false);

  const handleDateChangeDailyCash = (event) => {
    setSelectedDateDailyCash(event.target.value);
  };

  const handleDateChangeDailyOthers = (event) => {
    setSelectedDateDailyOthers(event.target.value);
  };

  const handleDownloadPDFDailyCash = async () => {
    try {
      setLoadingDailyCash(true);

      const date = new Date(selectedDateDailyCash);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/sales/daily-sales-cash/${year}/${month}/${day}`,
        { responseType: "json" }
      );

      const { sales, totalPrice } = response.data;

      if (!sales || sales.length === 0) {
        setLoadingDailyCash(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      doc.setProperties({
        title: `Rapport des ventes en cash du ${selectedDateDailyCash}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      doc.setFontSize(18);
      doc.text("Rapport des ventes en cash", 50, 60);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      let startY = 80;
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text("Rapport des ventes en cash", 50, 60);
        doc.setFontSize(10);
        doc.text(
          `Date: ${new Date().toLocaleDateString()}`,
          doc.internal.pageSize.width - 50,
          20
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < sales.length) {
        const sale = sales[transactionIndex];
        const {
          nomClient,
          userName,
          products,
          totalPrice,
          paymentMethod,
          date,
        } = sale;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nProduits: ${products
          .map((p) => `${p.productName} (x${p.quantity})`)
          .join(
            ", "
          )}\nMéthode de paiement: ${paymentMethod}\nTotal Prix: ${totalPrice} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50;

        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      doc.setFontSize(12);
      doc.text(
        `Total Montant: ${totalPrice.toLocaleString()} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      doc.save(`Ventes_espèces_du_${selectedDateDailyCash}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingDailyCash(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingDailyCash(false);
    }
  };

  const handleDownloadPDFDailyOthers = async () => {
    try {
      setLoadingDailyOthers(true);

      const date = new Date(selectedDateDailyOthers);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/sales/daily-sales-others/${year}/${month}/${day}`,
        { responseType: "json" }
      );

      const { sales, totalPrice } = response.data;

      if (!sales || sales.length === 0) {
        setLoadingDailyOthers(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      doc.setProperties({
        title: `Rapport des ventes des paiements de banque du ${selectedDateDailyOthers}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      doc.setFontSize(18);
      doc.text("Rapport des ventes  de paiements dans les banques", 50, 60);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      let startY = 80;
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text("Rapport des ventes de paiements dans les banques", 50, 60);
        doc.setFontSize(10);
        doc.text(
          `Date: ${new Date().toLocaleDateString()}`,
          doc.internal.pageSize.width - 50,
          20
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < sales.length) {
        const sale = sales[transactionIndex];
        const {
          nomClient,
          userName,
          products,
          totalPrice,
          paymentMethod,
          date,
        } = sale;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nProduits: ${products
          .map((p) => `${p.productName} (x${p.quantity})`)
          .join(
            ", "
          )}\nMéthode de paiement: ${paymentMethod}\nTotal Prix: ${totalPrice} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50;

        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      doc.setFontSize(12);
      doc.text(
        `Total Montant: ${totalPrice.toLocaleString()} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      doc.save(`Ventes_banques_du_${selectedDateDailyOthers}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingDailyOthers(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingDailyOthers(false);
    }
  };

  //monthly codes pdf
  const [selectedDateMonthlyCash, setSelectedDateMonthlyCash] = useState("");
  const [loadingMonthlyCash, setLoadingMonthlyCash] = useState(false);

  const [selectedDateMonthlyOthers, setSelectedDateMonthlyOthers] =
    useState("");
  const [loadingMonthlyOthers, setLoadingMonthlyOthers] = useState(false);

  const handleDateChangeMonthlyCash = (event) => {
    setSelectedDateMonthlyCash(event.target.value);
  };

  const handleDateChangeMonthlyOthers = (event) => {
    setSelectedDateMonthlyOthers(event.target.value);
  };

  const handleDownloadPDFMonthlyCash = async () => {
    try {
      setLoadingMonthlyCash(true);

      const date = new Date(selectedDateMonthlyCash);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/sales/monthly-sales-cash/${year}/${month}/details`,
        { responseType: "json" }
      );

      const { sales, totalPrice } = response.data;

      if (!sales || sales.length === 0) {
        setLoadingMonthlyCash(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      doc.setProperties({
        title: `Rapport des ventes en cash du ${year}-${month}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      doc.setFontSize(18);
      doc.text("Rapport des ventes en cash", 50, 60);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      let startY = 80;
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text("Rapport des ventes en cash", 50, 60);
        doc.setFontSize(10);
        doc.text(
          `Date: ${new Date().toLocaleDateString()}`,
          doc.internal.pageSize.width - 50,
          20
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < sales.length) {
        const sale = sales[transactionIndex];
        const {
          nomClient,
          userName,
          products,
          totalPrice,
          paymentMethod,
          date,
        } = sale;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nProduits: ${products
          .map((p) => `${p.productName} (x${p.quantity})`)
          .join(
            ", "
          )}\nMéthode de paiement: ${paymentMethod}\nTotal Prix: ${totalPrice} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50;

        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      doc.setFontSize(12);
      doc.text(
        `Total Montant: ${totalPrice.toLocaleString()} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      doc.save(`Ventes_espèces_du_${year}_${month}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingMonthlyCash(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingMonthlyCash(false);
    }
  };

  const handleDownloadPDFMonthlyOthers = async () => {
    try {
      setLoadingMonthlyOthers(true);

      const date = new Date(selectedDateMonthlyOthers);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/sales/monthly-sales-others/${year}/${month}/details`,
        { responseType: "json" }
      );

      const { sales, totalPrice } = response.data;

      if (!sales || sales.length === 0) {
        setLoadingMonthlyOthers(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      doc.setProperties({
        title: `Rapport des ventes de paiements dans la banque du ${year}-${month}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      doc.setFontSize(18);
      doc.text("Rapport des ventes de paiements dans les banques", 50, 60);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      let startY = 80;
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text("Rapport des ventes de paiements dans les banques ", 50, 60);
        doc.setFontSize(10);
        doc.text(
          `Date: ${new Date().toLocaleDateString()}`,
          doc.internal.pageSize.width - 50,
          20
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < sales.length) {
        const sale = sales[transactionIndex];
        const {
          nomClient,
          userName,
          products,
          totalPrice,
          paymentMethod,
          date,
        } = sale;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nProduits: ${products
          .map((p) => `${p.productName} (x${p.quantity})`)
          .join(
            ", "
          )}\nMéthode de paiement: ${paymentMethod}\nTotal Prix: ${totalPrice} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50;

        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      doc.setFontSize(12);
      doc.text(
        `Total Montant: ${totalPrice.toLocaleString()} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      doc.save(`Ventes_banques_du_${year}_${month}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingMonthlyOthers(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingMonthlyOthers(false);
    }
  };

  //monthly checking
  const [selectedDateMoney, setSelectedDateMoney] = useState("");
  const [loadingMoney, setLoadingMoney] = useState(false);

  const handleDateChangeMoney = (event) => {
    setSelectedDateMoney(event.target.value);
  };

  const handleDownloadPDFMoney = async () => {
    try {
      setLoadingMoney(true);

      const date = new Date(selectedDateMoney);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/sales/monthly-aggregated-data/${year}/${month}`,
        { responseType: "json" }
      );

      const { totalSales, totalDeposit, totalDepense, totalIncome } =
        response.data;

      const doc = new jsPDF();
      doc.text(`Monthly Cash Payments Report - ${year}/${month}`, 10, 10);
      doc.text(`Total Depense: ${totalDepense.toLocaleString()} FBU`, 10, 20);
      doc.text(`Total Deposit: ${totalDeposit.toLocaleString()} FBU`, 10, 30);
      doc.text(
        `Total Cash Payments: ${totalSales.toLocaleString()} FBU`,
        10,
        40
      );
      doc.text(
        `Argent manquant: ${Number(
          totalSales - totalDepense - totalDeposit
        ).toLocaleString()} FBU`,
        10,
        50
      );

      doc.save(`Monthly_Cash_Payments_Report_${year}_${month}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingMoney(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoadingMoney(false);
    }
  };

  const columns = [
    { field: "createdAt", headerName: "Créé le", flex: 1 },
    { field: "name", headerName: "Nom", flex: 1 },
    { field: "desc", headerName: "Description", flex: 1 },
    { field: "price_vente", headerName: "Prix de Vente", flex: 1 },
    { field: "userName", headerName: "Effectué par", flex: 1 },

    { field: "status", headerName: "Statut", flex: 1 },
    {
      field: "edit",
      headerName: "Éditer",
      width: 100,
      renderCell: (params) => (
        <Link to={`/PendingProductEdit/${params.row.id}`}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            Éditer
          </Button>
        </Link>
      ),
    },
  ];

  const rows = pendingProducts.map((item) => ({
    id: item._id,
    name: item.name,
    desc: item.desc,
    price_vente: formatNumber(item.price_vente),
    userName: item.userName,
    status: "En attente",
    createdAt: item.createdAt.slice(0, 10),
  }));

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="TABLEAU DE BORD"
          subtitle="Bienvenue sur votre tableau de bord"
        />
      </FlexBetween>

      <Box display="flex" flexWrap="wrap" gap="20px" mt="20px">
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible dans la caisse aujourd’hui"
            value={formatNumber(dailyCash)}
            increase="fbu"
            description="Aujourd’hui"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent entrer dans les banques aujourd’hui"
            value={formatNumber(dailyBanks)}
            increase="fbu"
            description="Aujourd’hui"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible dans la caisse ce mois"
            value={formatNumber(monthlyCash)}
            increase="fbu"
            description="Ce mois"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible dans la banque ce mois"
            value={formatNumber(monthlyBanks)}
            increase="fbu"
            description="Ce mois"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="Intérêt journalier"
            value={formatNumber(dailyInterest)}
            increase="fbu"
            description="Depuis 0h00 aujourd'hui"
            icon={
              <Email
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Ventes d'aujourd'hui"
            value={formatNumber(dailySales)}
            increase="fbu"
            description="Depuis aujourd'hui"
            icon={
              <PointOfSale
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Ventes mensuelles"
            value={formatNumber(monthlySales)}
            increase="fbu"
            description="Pendant cet mois"
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Intérêt mensuel"
            value={formatNumber(monthlyInterest)}
            increase="fbu"
            description="Pendant cet mois"
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Ventes annuelles"
            value={formatNumber(yearlySales)}
            increase="fbu"
            description="Pendant cette anneé"
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Intérêt annuel"
            value={formatNumber(yearlyInterest)}
            increase="fbu"
            description="Pendant cette anneé"
            icon={
              <Traffic
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box mt="20px">
        <DataGrid rows={rows} columns={columns} autoHeight />
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements journaliers en cash
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateDailyCash}
              onChange={handleDateChangeDailyCash}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFDailyCash}
              disabled={!selectedDateDailyCash || loadingDailyCash}
            >
              {loadingDailyCash ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements journaliers dans les banques
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateDailyOthers}
              onChange={handleDateChangeDailyOthers}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFDailyOthers}
              disabled={!selectedDateDailyOthers || loadingDailyOthers}
            >
              {loadingDailyOthers ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements mensuels en cash
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner le Mois"
              type="month"
              value={selectedDateMonthlyCash}
              onChange={handleDateChangeMonthlyCash}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMonthlyCash}
              disabled={!selectedDateMonthlyCash || loadingMonthlyCash}
            >
              {loadingMonthlyCash ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements mensuels dans la banque
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner le Mois"
              type="month"
              value={selectedDateMonthlyOthers}
              onChange={handleDateChangeMonthlyOthers}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMonthlyOthers}
              disabled={!selectedDateMonthlyOthers || loadingMonthlyOthers}
            >
              {loadingMonthlyOthers ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les ventes mensuelles
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner le mois"
              type="month"
              value={selectedDate1}
              onChange={handleDateChange1}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF1}
              disabled={!selectedDate1 || loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "Télécharger le PDF"}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les ventes annuelles
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="year"
              color="secondary"
              label="Sélectionner l'année"
              type="number"
              value={selectedYear}
              onChange={handleYearChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFYear}
              disabled={!selectedYear || loadingYear}
            >
              {loadingYear ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Vérification des dépots de l'argent selon le mois choisi (inclus les
            jours)
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateMoney}
              onChange={handleDateChangeMoney}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMoney}
              disabled={!selectedDateMoney || loadingMoney}
            >
              {loadingMoney ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Le rapport des intérêts de vente annuels
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="year"
              color="secondary"
              label="Sélectionner l'année"
              type="number"
              value={selectedYear3}
              onChange={handleYearChange3}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF3}
              disabled={loading3}
            >
              {loading3 ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le rapport"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Le rapport des intérêts de vente mensuels
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="month"
              color="secondary"
              label="Sélectionner le mois"
              type="month"
              value={selectedMonth2}
              onChange={handleMonthChange2}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF2}
              disabled={!selectedMonth2 || loading2}
            >
              {loading2 ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le rapport"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;
