import React from "react";
import { AddBox } from "@mui/icons-material"; // Import AddBox for the create icon
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { Link } from "react-router-dom";

const DataGridVenteToolbar = () => {
    return (
        <FlexBetween>
            <GridToolbar />
            <Link to ="/transaction">
                <Button
                    color="secondary"
                    variant="contained"
                    style={{ marginBottom: "6px" }}
                >
                    <AddBox /> Vendre
                </Button>
            </Link>
        </FlexBetween>
    );
};

export default DataGridVenteToolbar;
