import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const Popup = (props) => {
  const { title, children, openPopup, setOpenPopup } = props;

  return (
    <Dialog
      open={openPopup}
      onClose={() => setOpenPopup(false)}
      maxWidth="lg" // Options: 'xs', 'sm', 'md', 'lg', 'xl'
      fullWidth
      PaperProps={{
        style: {
          minHeight: '70vh', // Minimum height
          maxHeight: '90vh', // Maximum height
          minWidth: '60vw',  // Minimum width
          maxWidth: '80vw'   // Maximum width
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => setOpenPopup(false)}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
