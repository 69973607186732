import { Lock } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Container } from "@mui/material";
import React from "react";

const AccessDenied = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Alert severity="error" variant="outlined">
        <AlertTitle>Accès refusé</AlertTitle>
        Essayer de contacter le directeur général pour lui demander l'accès
        <Button variant="outlined" sx={{ ml: 2 }} startIcon={<Lock />} />
      </Alert>
    </Container>
  );
};

export default AccessDenied;
