import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";

const DepenseEdit = () => {
  const { id } = useParams();
  const [prestation, setPrestation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    userName: "",
    prix: 0,
  });

  useEffect(() => {
    const fetchPrestation = async () => {
      const IdPrestation = localStorage.getItem("depenseId");
      try {
        const response = await axios.get(
          `${server}/depense/get-depense/${IdPrestation}/${id}`
        );
        setPrestation(response.data.prestation);
        // Populate formData with initial values from prestation
        setFormData({
          name: response.data.prestation.name,
          desc: response.data.prestation.desc,
          price: response.data.prestation.price,
          userName: "editer par superadmin",
        });
      } catch (error) {
        setError("Error fetching depense");
      } finally {
        setLoading(false);
      }
    };

    fetchPrestation();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const IdPrestation = localStorage.getItem("depenseId");
      const response = await axios.put(
        `${server}/depense/update-depense/${IdPrestation}/${id}`,
        formData
      );
      toast.success("Expense updated successful");
      setTimeout(() => {
        window.location.replace("/depenses");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {prestation ? (
        <div>
          <Box m="20px">
            <FlexBetween>
              <Header title="LES DETAILS DE LA PRESTATION" />
            </FlexBetween>

            <Formik>
              {({ values, errors, touched, handleBlur, handleChange }) => (
                <form onSubmit={handleUpdate}>
                  <Box
                    display="grid"
                    marginTop="20px"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`Nom de la dépense: ${prestation.name} `}
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      name="Nom de la dépense"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`la description: ${prestation.desc} `}
                      value={formData.desc}
                      onChange={(e) =>
                        setFormData({ ...formData, desc: e.target.value })
                      }
                      name="la description"
                      sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`c : ${prestation.price} `}
                      value={formData.price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          price: parseInt(e.target.value),
                        })
                      }
                      name="la description"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button color="secondary" variant="contained" type="submit">
                      Mettre à jour la dépense
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      ) : (
        <div>No expense found</div>
      )}
    </div>
  );
};

export default DepenseEdit;
