import { Lock } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const AccesDeniedDashboard = () => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  return (
    <Container sx={{ py: 5 }}>
      <Alert severity="success" variant="outlined">
        <AlertTitle>Bienvenu dans Bismos High Tech</AlertTitle>
        <strong>{data?.name}</strong>, soyez le bienvenu dans l'espace des
        employés chez Bismos
        <Button variant="outlined" sx={{ ml: 2 }} startIcon={<Lock />}>
          Acceuil
        </Button>
      </Alert>
    </Container>
  );
};

export default AccesDeniedDashboard;
