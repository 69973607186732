import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormAddDepot = ({ openPopup, setOpenPopup, id }) => {
  const [bankAccount, setBankAccount] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${server}/deposit/create-deposit-transaction`,
        {
          userName: data.name,
          bankAccount,
          depositAmount: parseFloat(depositAmount),
        },
        { withCredentials: true }
      );

      if (response.data.message) {
        toast.success(response.data.message);
        window.location.reload(true);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error creating payment history:", error);
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="AJOUTER UN AUTRE DEPOT" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <Formik>
        {({ handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="La somme déposer"
                onBlur={handleBlur}
                onChange={(e) => setDepositAmount(e.target.value)}
                value={depositAmount}
                name="La somme déposer"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                select
                fullWidth
                variant="filled"
                label="Dépot sur la banque"
                value={bankAccount}
                onChange={(e) => setBankAccount(e.target.value)}
                name="Dépot sur la banque"
              >
                <MenuItem value="BANCOBU">BANCOBU</MenuItem>
                <MenuItem value="BCB">BCB</MenuItem>
                <MenuItem value="KCB">KCB</MenuItem>
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="IBB">IBB</MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button color="secondary" variant="contained" type="submit">
                Ajouter un autre dépot
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  Nom: yup.string().required("required"),
  MontantReel: yup.string().required("required"),
  total: yup.string().required("required"),
});
const initialValues = {
  Nom: "",
  interieur: "",
  Couverture: "",
  rabatintercalaire: "",
  total: "",
  MontantReel: "",
};

export default FormAddDepot;
