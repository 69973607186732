import "./userList.css";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Box, Button, Typography, useTheme } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { tokens } from "theme";
import Header from "components/Header";
import Popup from "components/Popup";
import FormPrestation from "scenes/formPrestation";
import FormCreateUser from "scenes/formCreateUser";
import { backend_url, server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../../state/api";
import PersonIcon from "@mui/icons-material/Person";
// import { useGetUsersQuery } from "state/api";

export default function Team() {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopup, setOpenPopup] = useState(false);
  const [users, setUsers] = useState([]);
  // const { data } = useGetUsersQuery();

  // console.log(data);

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/user/`);
        // const users = response.data.papiers || []; // Access prestations array directly or use empty array if not available
        setUsers(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this user?")) {
        const response = await axios.delete(`${server}/user/${id}`);
        // Assuming your backend returns a success message upon deletion
        if (response) {
          // Refetch data after deletion or update UI as needed
          toast.success("User deleted successfully");

          window.location.reload(true);

          // Optionally, you can navigate to a different route upon successful deletion
          // history.push("/some-route");
        } else {
          // Handle deletion failure
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("user not found");
    }
  };
  const columns = [
    {
      field: "profilepic",
      headerName: "Utilisateur",
      width: 200,
      renderCell: (params) => (
        <div className="userListUser">
          {/* Display profile picture if available, otherwise show default image */}
          {params.row.profilepic ? (
            <img
              className="userListImg"
              src={params.row.profilepic.url}
              alt={params.row.name}
            />
          ) : (
            <PersonIcon className="userListImg" />
          )}
          {params.row.name}
        </div>
      ),
    },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "occupation",
      headerName: "Status",
      width: 120,
    },
    {
      field: "role",
      headerName: "Access Level",
      width: 160,
      renderCell: ({ row: { role } }) => {
        return (
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px", width: "160px" }}
          >
            {role === "superadmin" && <SecurityOutlinedIcon />}
            {role === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {role === "caissiere" && <LockOpenOutlinedIcon />}
            {role === "gestionnaire" && <LockOpenOutlinedIcon />}
            {role === "receptioniste" && <LockOpenOutlinedIcon />}
            <Typography sx={{ ml: "5px" }}>{role}</Typography>
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/team/" + params.row.id}>
              <button className="userListEdit">Edit</button>
            </Link>
            {
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            }
          </>
        );
      },
    },
  ];

  return (
    <div className="userList">
      <Box m="1.5rem 2.5rem">
        <Header title="Listes des super agents" />
        <Box
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={users.map((user) => ({
              id: user._id, // Assuming _id is the unique identifier
              name: user.name,
              profilepic: user.profilepic,
              email: user.email,
              occupation: user.occupation,
              role: user.role,
            }))}
            disableSelectionOnClick
            columns={columns}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { openPopup, setOpenPopup },
            }}
          />
        </Box>
        <Popup
          title="Ajouter un nouveau utilisateur"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <FormCreateUser setOpenPopup={setOpenPopup} />
        </Popup>
      </Box>
    </div>
  );
}
