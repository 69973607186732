import React, { useEffect, useState } from "react";
import Header from "components/Header"; // Assuming Header component exists
import {
  Box,
  Grid,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
  Container,
  Paper,
  useTheme,
} from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { styled } from "styled-components";
// import papers from "../../data/paper";
// import impression from "data/typeImpression";
import axios from "axios";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../server";
import "./devis.css";
import FlexBetween from "components/FlexBetween";
import Popup from "components/Popup";
import FormNumerotation from "scenes/formNumerotation";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const useStyles = () => ({});

const theme = createTheme({
  palette: {
    text: {
      primary: "#000", // Set default text color to black
    },
  },
});

const FormSample = () => {
  const theme = useTheme();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  //  {handleling selection interieur}
  const [selectedPaper, setSelectedPaper] = useState("");
  const [selectedPaper1, setSelectedPaper1] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedPrice1, setSelectedPrice1] = useState("");
  const [selectedImpression, setSelectedImpression] = useState("");
  const [selectedImpressionCouverture, setSelectedImpressionCouverture] =
    useState("");
  const [selectedPriceImpression, setSelectedPriceImpression] = useState("");
  const [
    selectedPriceImpressionCouverture,
    setSelectedPriceImpressionCouverture,
  ] = useState("");
  const [nomClient, setNomClient] = useState("");
  const [personContact, setPersonContact] = useState("");
  const [personContactNumber, setPersonContactNumber] = useState("");
  const [papier2Calcule, setPapier2Calcule] = useState(0);
  const [prixPerforation, setPrixPerforation] = useState("");
  const [prixNumerotation, setPrixNumerotation] = useState("");

  const handlePaperChange = (event) => {
    const selectedName = event.target.value;
    const selectedPaper = papers.find(
      (paper) => paper.namePaper === selectedName
    );
    if (selectedPaper) {
      setSelectedPaper(selectedName);
      setSelectedPrice(selectedPaper.price);
    }
  };

  const handlePaperChangeImpression = (event) => {
    const selectedName = event.target.value;
    const selectedImpression = impression.find(
      (impression) => impression.nameImpression === selectedName
    );
    if (selectedImpression) {
      setSelectedImpression(selectedName);
      setSelectedPriceImpression(selectedImpression.price);
    }
  };
  const handlePaperChangeImpression1 = (event) => {
    const selectedName = event.target.value;
    const selectedImpressionCouverture = impression.find(
      (impression) => impression.nameImpression === selectedName
    );
    if (selectedImpressionCouverture) {
      setSelectedImpressionCouverture(selectedName);
      setSelectedPriceImpressionCouverture(selectedImpressionCouverture.price);
    }
  };

  const classes = useStyles();
  const [items, setItems] = useState([]);
  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/prestation/get-prestations`
        );
        const prestationsArray = response.data.prestations || []; // Access prestations array directly or use empty array if not available
        setItems(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [formData, setFormData] = useState([
    items.map((item) => ({
      prestation: item.prestation,
      interieur: item.interieur,
      couverture: item.couverture,
      rabatintercalaire: item.rabatintercalaire,
      total: item.total,
      MontantReel: item.MontantReel,
    })),
  ]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData];
    list[index][name] = value;
    setFormData(list);
  };

  const handleCheckboxChange = (index1) => {
    const updatedItems = [...items];
    updatedItems[index1].isChecked = !updatedItems[index1].isChecked;
    setItems(updatedItems);
  };
  const [totalPrestation, setTotalPrestation] = useState(0);
  useEffect(() => {
    const handlecompute = () => {
      const checkedItemsData = items.filter((item) => item.isChecked);
      const total = checkedItemsData.reduce((acc, curr) => {
        return acc + Number(curr.montantReel);
      }, 0);
      return total;
    };

    // Call handlecompute and set the totalPrestation state
    setTotalPrestation(handlecompute());
  }, [items]);

  // {states for calculation}
  const [rognage, setRognage] = useState(0);
  const [nombrePage, setNombrePage] = useState(0);
  const [nombrePageCouverture, setNombrePageCouverture] = useState(0);
  const [nombrePerforation, setNombrePerforation] = useState(0);
  const [nombreNumerotation, setNombreNumerotation] = useState(0);
  const [papierInterieur, SetPapierInterieur] = useState(0);
  const [papierCouverture, setPapierCouverture] = useState(0);
  const [couleurInterieur, setCouleurInterieur] = useState(0);
  const [couleurCouverture, setCouleurCouverture] = useState(0);
  const [TotalHorsTax, setTotalHorsTax] = useState(0);
  const [TVA, setTVA] = useState(0);
  const [TTC, setTTC] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  useEffect(() => {
    SetPapierInterieur(selectedPrice);
    setPapierCouverture(selectedPrice1);
  }, [nombrePage, selectedPrice, selectedPrice1]);

  //fetching perforation and numerotation prices
  useEffect(() => {
    // Fetch impression details by ID
    const fetchImpression = async () => {
      try {
        const IdImpression = localStorage.getItem("impressionId");
        const response = await axios.get(
          `${server}/impression/impression/${IdImpression}`
        );

        setPrixPerforation(response.data.perforationPrice);
        setPrixNumerotation(response.data.numerotationPrice);
      } catch (error) {
        console.error("Error fetching impression details:", error);
      }
    };

    fetchImpression();
  }, []);

  //prix du couleurs sera dynamique
  useEffect(() => {
    const papier1 =
      Number(nombrePage) * Number(papierInterieur) * Number(couleurInterieur);
    if (Number(couleurCouverture) === 0) {
      const papier2 = Number(nombrePageCouverture) * Number(papierCouverture);
      setPapier2Calcule(papier2);
    } else {
      const papier2 =
        Number(nombrePageCouverture) *
        Number(papierCouverture) *
        Number(couleurCouverture);
      setPapier2Calcule(papier2);
    }
    const impressionCalcule =
      Number(selectedPriceImpression) * Number(nombrePage);

    const impressionCalculeCouverture =
      Number(selectedPriceImpressionCouverture) * Number(nombrePageCouverture);

    const lesPrestations = Number(totalPrestation) + Number(rognage);

    const lesPerforation = Number(prixPerforation) * Number(nombrePerforation);
    const lesNumerotation =
      Number(prixNumerotation) * Number(nombreNumerotation);

    // const final =
    //   Number(prestationtotal) + Number(impression) + Number(papier1);
    // console.log(final);
    // console.log(impression);
    // console.log(prestationtotal);
    // console.log(papier1);

    const newTotalHorsTax =
      Number(lesPrestations) +
      Number(impressionCalcule) +
      Number(papier1) +
      Number(papier2Calcule) +
      Number(lesPerforation) +
      Number(impressionCalculeCouverture) +
      Number(lesNumerotation);

    const newTVA = (newTotalHorsTax * 18) / 100;

    const newTTC = newTotalHorsTax + newTVA;
    // console.log(TotalHorsTax1);
    // console.log(TotalHorsTax2);
    // console.log(newTotalHorsTax);

    setTotalHorsTax(newTotalHorsTax);
    setTVA(newTVA);
    setTTC(newTTC);
  }, [
    couleurCouverture,
    nombrePage,
    couleurInterieur,
    nombrePageCouverture,
    papier2Calcule,
    papierCouverture,
    selectedPriceImpression,
    papierInterieur,
    totalPrestation,
    rognage,
    nombrePerforation,
    selectedImpressionCouverture,
    nombreNumerotation,
    selectedPriceImpressionCouverture,
    prixNumerotation,
    prixPerforation,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${server}/generate-pdf`,
        {
          TotalHorsTax,
          TVA,
          TTC,
          nombrePage,
          selectedPaper,
          couleurInterieur,
          selectedPaper1,
          couleurCouverture,
          nomClient,
          personContact,
          selectedPrice,
          selectedPrice1,
          nombrePageCouverture,
          personContactNumber,
          selectedImpression,
          selectedPriceImpression,
          selectedImpressionCouverture,
          selectedPriceImpressionCouverture,
          userName: data.name,
        },
        { withCredentials: true }
      );
      toast.success("PDF generation successful");
      setTimeout(() => {
        window.location.replace("/pdf");
      }, 2000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

  //dynamic impression
  const [impression, setImpression] = useState([]);
  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/impression/get-impressions`
        );
        const impressionsArray = response.data.impressions || []; // Access prestations array directly or use empty array if not available
        setImpression(impressionsArray);
        // console.log(impressionsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //fetching papers interieur
  const [papers, setPapers] = useState([]);
  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/papier/get-papiers`);
        const papiersArray = response.data.papiers || []; // Access prestations array directly or use empty array if not available
        setPapers(papiersArray);
        console.log(papiersArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //fetching papers couvertures

  const [paperCover, setPaperCover] = useState([]);
  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/papier/get-papiers-couverture`
        );
        const papiersArray = response.data.papiers || []; // Access prestations array directly or use empty array if not available
        setPaperCover(papiersArray);
        console.log(papiersArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePaperChange1 = (event) => {
    const selectedName = event.target.value;
    const selectedPaper1 = paperCover.find(
      (paper) => paper.namePaper === selectedName
    );
    if (selectedPaper1) {
      setSelectedPaper1(selectedName);
      setSelectedPrice1(selectedPaper1.price);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box m="1.5rem 2.5rem">
        <Header title="Module du Devis" subtitle="Devis et facturer proforma" />

        {data?.role === "superadmin" && (
          <Grid style={{ display: "flex", marginTop: "10px" }}>
            <Button
              color="secondary"
              variant="contained"
              style={{
                marginBottom: "6px",
                marginRight: "30px",
                fontWeight: "bold",
              }}
              endIcon={<SendIcon />}
            >
              {" "}
              <Link
                to="/prix"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Changer les prix des prestations
              </Link>
            </Button>

            <Button
              color="secondary"
              variant="contained"
              style={{
                marginBottom: "6px",
                marginRight: "30px",
                fontWeight: "bold",
              }}
              endIcon={<SendIcon />}
            >
              {" "}
              <Link
                to="/papier"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "bold",
                }}
              >
                Changer les prix des papiers interieurs
              </Link>
            </Button>
            <Button
              color="secondary"
              variant="contained"
              style={{
                marginBottom: "6px",
                marginRight: "30px",
                fontWeight: "bold",
              }}
              endIcon={<SendIcon />}
            >
              {" "}
              <Link
                to="/papier-couverture"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "bold",
                }}
              >
                Changer les prix des papiers des couvertures
              </Link>
            </Button>
            <Button
              color="secondary"
              variant="contained"
              style={{
                marginBottom: "6px",
                marginRight: "30px",
                fontWeight: "bold",
              }}
              endIcon={<SendIcon />}
            >
              {" "}
              <Link
                to="/impression"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "bold",
                }}
              >
                Changer les prix des impressions
              </Link>
            </Button>
          </Grid>
        )}
        <Grid style={{ display: "flex", marginTop: "10px" }}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
            endIcon={<SendIcon />}
          >
            {" "}
            <Link
              to="/pdf"
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
            >
              Voir les factures
            </Link>
          </Button>
        </Grid>
        {data?.role === "superadmin" && (
          <FlexBetween style={{ margin: "10px", marginLeft: "0px" }}>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginBottom: "6px" }}
            >
              <div style={{ flexDirection: "row" }}>
                <h3>LES PRIX POUR :</h3>
                <h3>La perforation: {prixPerforation} fbu</h3>
                <h3>La numerotation: {prixNumerotation} fbu</h3>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={() => setOpenPopup(!openPopup)}
                >
                  Changer les prix
                </Button>
              </div>
            </Button>
          </FlexBetween>
        )}

        <Container sx={{ width: "auto", marginTop: "20px" }}>
          <Paper
            elevation={3}
            sx={{ borderRadius: 4, padding: "2rem", height: "4100px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="2rem"
                >
                  <Typography variant="h3">Feuille de Devis</Typography>
                  {/* Add your logo component here */}
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  Informations générales
                </Typography>
                <Typography gutterBottom>Numero: ...........</Typography>
                <Typography gutterBottom>Date: .../..../...</Typography>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Client</Typography>
                <TextField
                  label="Client"
                  placeholder="Entrez le nom du client"
                  value={nomClient}
                  onChange={(e) => setNomClient(e.target.value)}
                  required
                />
                <TextField
                  label="Personne de contact"
                  placeholder="Entrez le nom de la personne de contact"
                  value={personContact}
                  onChange={(e) => setPersonContact(e.target.value)}
                  required
                />
                <TextField
                  label="Numero de téléphone contact"
                  placeholder="Entrez le numero de téléphone de la personne de contact"
                  value={personContactNumber}
                  onChange={(e) => setPersonContactNumber(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Détails</Typography>
                <TextField
                  label="Nombre de pages interieur"
                  type="number"
                  placeholder="Entrez le nombre de pages"
                  onChange={(e) => setNombrePage(e.target.value)}
                  required
                />
                <TextField
                  label="Nombre de pages couverture"
                  type="number"
                  placeholder="Entrez le nombre de pages"
                  onChange={(e) => setNombrePageCouverture(e.target.value)}
                  required
                />
                <TextField
                  type="number"
                  label="prix du rognage"
                  placeholder="Rognage"
                  required
                  onChange={(e) => setRognage(e.target.value)}
                />

                <TextField
                  label="Format fini"
                  placeholder="Format fini"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Impression</Typography>
                <TextField
                  label="Nombre couleurs intérieure"
                  type="number"
                  onChange={(e) => setCouleurInterieur(e.target.value)}
                  placeholder="Entrez le nombre de couleurs intérieures"
                  required
                />
                <TextField
                  label="Nombre couleurs couverture"
                  type="number"
                  placeholder="Entrez le nombre de couleurs de couverture"
                  onChange={(e) => setCouleurCouverture(e.target.value)}
                  required
                />
                <Typography variant="subtitle1">
                  Nombre de page à perforer
                </Typography>
                <TextField
                  label="Si la perforation est applicable"
                  type="number"
                  onChange={(e) => setNombrePerforation(e.target.value)}
                  placeholder="Entrez le Nombre de page a perforer"
                  required
                />
                <Typography variant="subtitle1">
                  Nombre de page à numeroter
                </Typography>
                <TextField
                  label="si la numeration est applicable"
                  type="number"
                  onChange={(e) => setNombreNumerotation(e.target.value)}
                  placeholder="Entrez le Nombre de page a perforer"
                  required
                />

                <Typography variant="subtitle1">
                  Selectionner le type d'impression du papier interieur
                </Typography>

                <select
                  id="impression"
                  value={selectedImpression}
                  onChange={handlePaperChangeImpression}
                >
                  <option value="">
                    Selectionner le type d'impression et format du papier:
                  </option>{" "}
                  {impression.map((impression, index) => (
                    <option key={index} value={impression.nameImpression}>
                      {impression.nameImpression}
                    </option>
                  ))}
                </select>
                <br />
                <TextField
                  label="Coût du type d'impression selectionner par unité"
                  id="price"
                  type="number"
                  placeholder="Coût du type d'impression selectionner par unité"
                  required
                  value={selectedPriceImpression}
                  readOnly
                  style={{ marginTop: "10px" }}
                />
                {/* {selection d'impression papier couverture} */}

                <Typography variant="subtitle1">
                  Selectionner le type d'impression du papier couverture(si
                  applicable)
                </Typography>

                <select
                  id="impression"
                  value={selectedImpressionCouverture}
                  onChange={handlePaperChangeImpression1}
                >
                  <option value="">
                    Selectionner le type d'impression à utiliser:
                  </option>{" "}
                  {impression.map((impression, index) => (
                    <option key={index} value={impression.nameImpression}>
                      {impression.nameImpression}
                    </option>
                  ))}
                </select>
                <br />
                <TextField
                  label="Coût du type d'impression selectionner par unité"
                  id="price"
                  type="number"
                  placeholder="Coût du type d'impression selectionner par unité"
                  required
                  value={selectedPriceImpressionCouverture}
                  readOnly
                  style={{ marginTop: "10px" }}
                />
              </Grid>

              {/* {papier interieur} */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  Selectionner les types des papiers
                </Typography>
                <Typography variant="subtitle1">
                  Coût du papier intérieur
                </Typography>

                <select
                  id="papers"
                  value={selectedPaper}
                  onChange={handlePaperChange}
                >
                  <option value="">
                    Selectionner le type de papier et grammage:
                  </option>{" "}
                  {papers.map((paper, index) => (
                    <option key={index} value={paper.namePaper}>
                      {paper.namePaper}
                    </option>
                  ))}
                </select>
                <br />
                <TextField
                  label="Coût du papier intérieur"
                  id="price"
                  type="number"
                  placeholder="Coût du papier intérieur"
                  required
                  value={selectedPrice}
                  readOnly
                  style={{ marginTop: "10px" }}
                />

                {/* {papier exterieur} */}

                <Typography variant="subtitle1">
                  Coût du papier du couverture
                </Typography>

                <select
                  id="papers"
                  value={selectedPaper1}
                  onChange={handlePaperChange1}
                >
                  <option value="">
                    Selectionner le type de papier et grammage:
                  </option>{" "}
                  {paperCover.map((paper, index) => (
                    <option key={index} value={paper.namePaper}>
                      {paper.namePaper}
                    </option>
                  ))}
                </select>
                <br />
                <TextField
                  label="Coût du papier du couverture"
                  id="price"
                  type="number"
                  placeholder="Coût du papier du couverture"
                  required
                  value={selectedPrice1}
                  readOnly
                  style={{ marginTop: "10px" }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Finition</Typography>
                <TextField label="Faconnage" placeholder="Faconnage" required />
                <TextField label="Emballage" placeholder="Emballage" required />
              </Grid>
            </Grid>
            <>
              <br />
              <hr />
              <Form>
                {formData.map((item, index) => (
                  <Container key={index}>
                    <Row className="mt-2" style={{ width: "auto" }}>
                      {items.map((item1, index1) => (
                        <div key={index1} className="flex-container">
                          <input
                            type="checkbox"
                            checked={item1.isChecked || false}
                            onChange={() => handleCheckboxChange(index1)}
                            className="checkbox"
                          />
                          <h3>
                            <strong>{item1.name}</strong>
                          </h3>
                          <div className="flex-row">
                            <Col className="col">
                              <Form.Group>
                                <Form.Label>interieur</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="entre la somme"
                                  name="interieur"
                                  value={
                                    (item1.isChecked && item1.interieur) || ""
                                  }
                                  onChange={() => handleCheckboxChange(index1)}
                                  className="input-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col className="col">
                              <Form.Group>
                                <Form.Label>couverture</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="entre la somme"
                                  name="cost"
                                  value={
                                    item1.isChecked ? item1.couverture : ""
                                  }
                                  onChange={() => handleCheckboxChange(index1)}
                                  className="input-field"
                                />
                              </Form.Group>
                            </Col>
                            <Col className="col">
                              <Form.Group>
                                <Form.Label>rabatintercalaire</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="entre la somme"
                                  name="rabatintercalaire"
                                  value={
                                    item1.isChecked
                                      ? item1.rabatintercalaire
                                      : ""
                                  }
                                  onChange={() => handleCheckboxChange(index1)}
                                  className="input-field"
                                />
                              </Form.Group>
                            </Col>
                          </div>
                          <Col className="col-padding">
                            <Form.Group>
                              <Form.Label>total</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="la somme total"
                                name="tax"
                                value={item1.isChecked ? item1.total : ""}
                                onChange={() => handleCheckboxChange(index1)}
                                className="input-field"
                              />
                            </Form.Group>
                          </Col>
                          <Col className="col-padding">
                            <Form.Group>
                              <Form.Label>Montant reel</Form.Label>
                              <Form.Control
                                readOnly
                                type="number"
                                placeholder="la somme du Montant Reel"
                                name="tax"
                                value={item1.isChecked ? item1.montantReel : ""}
                                onChange={() => handleCheckboxChange(index1)}
                                className="input-field"
                              />
                            </Form.Group>
                          </Col>
                        </div>
                      ))}
                    </Row>
                  </Container>
                ))}
              </Form>
              <hr />
              <br />
              <br />
              <br />

              <Total className={classes.Total}>
                <div>
                  <Row>
                    <Col>Total général des prestations</Col>
                    <Col>
                      {/* <p> {handlecompute()} fbu</p> */}
                      <TextField
                        type="number"
                        value={totalPrestation}
                        onChange={(e) => setTotalPrestation(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Total général hors taxes</Col>
                    <Col>
                      <p> {TotalHorsTax} fbu</p>
                    </Col>
                  </Row>
                  <>
                    <Row>
                      <Col xs={8}> le TVA de 18 % :</Col>
                      <Col>
                        <p> {TVA} fbu</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col xs={7}> Prix Total ou TTC:</Col>
                      <Col>
                        <p> {TTC} fbu</p>
                      </Col>
                    </Row>
                  </>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      marginTop: "15px",
                      display: "left",
                    }}
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                  >
                    Générer la facturer proforma
                  </Button>
                </div>
              </Total>
            </>
          </Paper>
        </Container>
        <Popup
          title="changer les prix de numérotation et perforation"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <FormNumerotation setOpenPopup={setOpenPopup} />
        </Popup>
      </Box>
    </ThemeProvider>
  );
};

export default FormSample;

const Total = styled.div`
  position: absolute;
  display: flex;
  flex-direction: flex-end;
  right: 40% !important;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
