import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import axios from "axios";
import { server } from "server";
import { Link } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";

const Product = ({
  _id,
  name,
  desc,
  price_achat,
  price_vente,
  category,
  stock,
}) => {
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          categorie: {category}
        </Typography>
        <Typography variant="h5" component="div">
          Nom: <strong>{name}</strong>
        </Typography>
        <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
          Prix d'achat : <strong>{Number(price_achat)} fbu</strong>
          <br />
          Prix de vente : <strong>{Number(price_vente)} fbu</strong>
        </Typography>
        <Typography variant="body2" color="red">
          Quantité en stock: <strong color="red">{stock}</strong>
        </Typography>
        <Typography variant="body2">{desc}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Voir plus
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          <Typography>Quantité disponible: {stock}</Typography>
          <Typography>categorie: {category}</Typography>
          <Link to={`/productEdit/${_id}`}>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              style={{ margin: "10px" }}
            >
              Ajouter en stock
            </Button>
          </Link>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Avertissement = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const filterProducts = (products) => {
    return products.filter((product) => product.stock <= 40);
  };

  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const filteredProducts = filterProducts(products);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="LISTE DES PRODUITS QUI S'ECROULENT"
        subtitle="La liste des produits qui vont finir bientot."
      />
      {filteredProducts.length > 0 ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {filteredProducts.map(
            ({
              _id,
              name,
              desc,
              price_vente,
              price_achat,
              category,
              stock,
            }) => (
              <Product
                key={_id}
                _id={_id}
                name={name}
                desc={desc}
                price_achat={price_achat}
                price_vente={price_vente}
                category={category}
                stock={stock}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Avertissement;
