import { Box, Button, TextField, IconButton } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormProduct = ({ setOpenPopup }) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [category, setCategory] = useState("");
  const [price_achat, setPrice_achat] = useState("");
  const [price_vente, setPrice_vente] = useState("");
  const [barcode, setBarcode] = useState("");
  const [stock, setStock] = useState("");
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name || !desc ||!category || !price_achat || !price_vente || !stock) {
      toast.error("Veuillez remplir tous les champs du formulaire");
      return;
    }

    const userId = localStorage.getItem("secretKeyesforzado7");
    try {
      const formInfo = {
        name,
        category,
        desc,
        price_achat,
        price_vente,
        stock,
        userId,
        userName: data.name,
        barcode
      };
      const res = await axios.post(
        `${server}/product/create-product`,
        formInfo,
        { withCredentials: true }
      );
      // Check response status
      if (res.status === 201) {
        toast.success("Produit créé avec succès");
        window.location.reload(true);
      } else {
        toast.error("Erreur lors de la création du produit");
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Erreur inattendue lors de la création du produit");
      }
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="Créer un produit" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Nom d'un produit"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="categorie"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Prix d'achat"
            value={price_achat}
            onChange={(e) => setPrice_achat(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Prix de vente"
            value={price_vente}
            onChange={(e) => setPrice_vente(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Quantité"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Barcode"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
          />
        </Box>
        <Box mt="20px">
          <Button type="submit" variant="contained" color="primary">
            Créer un produit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormProduct;
