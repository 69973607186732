import axios from "axios";
import React, { useState } from "react";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const FormPassword = ({ openPopup, setOpenPopup }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Password has been changed successfully!");
        setTimeout(() => {
          setOpenPopup(false);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }, 1500);
      })
      .catch((error) => {
        toast.error(
          "Veuillez saisir correctement ton ancien et nouveau mot de passe"
        );
      });
  };

  return (
    <div>
      <div>
        <Box m="20px">
          <FlexBetween>
            <Header title="CHANGER TON MOT DE PASSE" />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setOpenPopup(false)}
            >
              X
            </Button>
          </FlexBetween>

          <Formik>
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <form>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showPassword ? "text" : "password"} // Toggle password visibility
                    label="Veuillez entre ton ancien mot de passe"
                    name="Ancien mot de passe"
                    onBlur={handleBlur}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showPassword1 ? "text" : "password"} // Toggle password visibility
                    label="Veuillez entre le nouveau mot de passe"
                    name="Nouveau mot de passe"
                    onBlur={handleBlur}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword1((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type={showPassword2 ? "text" : "password"} // Toggle password visibility
                    label="Veuillez confirmer ton nouveau mot de passe"
                    name="confirmer ton mot de passe"
                    onBlur={handleBlur}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword2((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={passwordChangeHandler}
                  >
                    Mettre à jour
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};

export default FormPassword;
