import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

const Popup = (props) => {

    const {title , children, openPopup,setOpenPopup} = props

  return (
    <div>
        <Dialog open ={openPopup}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    </div>
  )
}

export default Popup