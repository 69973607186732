import React, { useEffect, useState, useRef } from "react";
import Select, { components } from "react-select";  // Ensure you have installed react-select
import "./style.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";

const Transactions = () => {
  const [customerName, setCustomerName] = useState("");
  const [products, setProducts] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().slice(0, 10));
  const [paymentMethod, setPaymentMethod] = useState(""); // New state for payment method
  const [userId, setUserId] = useState(null);
  const [tableData, setTableData] = useState([{ id: 1, nom: "", categorie: "", description: "", prix: 0, quantite: 0 }]);
  const [errors, setErrors] = useState({});  // State for validation errors

  const printRef = useRef();

  const handleChange = (setter) => (event) => setter(event.target.value);

  const handleAddRow = () => {
    setTableData([
      ...tableData,
      {
        id: tableData.length + 1,
        nom: "",
        categorie: "",
        description: "",
        prix: 0,
        quantite: 0,
      },
    ]);
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, index) => index !== rowIndex));
    }
  };

  const handleRowChange = (rowIndex, key) => (event) => {
    const value = parseFloat(event.target.value) || 0;
    const updatedData = [...tableData];
    updatedData[rowIndex][key] = value;
    setTableData(updatedData);
  };

  useEffect(() => {
    const getUserIdFromStorage = async () => {
      const storedUserId = localStorage.getItem("secretKeyesforzado7");
      if (storedUserId) {
        setUserId(storedUserId);
      }
    };

    getUserIdFromStorage();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleProductSelection = (rowIndex) => async (selectedOption) => {
    const updatedData = [...tableData];
    const selectedProductId = selectedOption.value;
    updatedData[rowIndex].item = selectedProductId;

    try {
      const response = await axios.get(`${server}/product/a-product/${selectedProductId}`);
      const product = response.data.products;
      updatedData[rowIndex].prix = product.price_vente;
      updatedData[rowIndex].categorie = product.category;
      updatedData[rowIndex].description = product.desc;
      updatedData[rowIndex].nom = product.name;
      setTableData(updatedData);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!customerName) newErrors.customerName = "Le nom du client est requis";
    if (!paymentMethod) newErrors.paymentMethod = "Le mode de paiement est requis";
    tableData.forEach((row, index) => {
      if (!row.nom) newErrors[`item${index}`] = "Le produit est requis";
      if (!row.quantite || row.quantite <= 0) newErrors[`quantite${index}`] = "La quantité doit être supérieure à 0";
    });
    setErrors(newErrors);
    // Display toast for each validation error
    Object.keys(newErrors).forEach(key => {
      toast.error(newErrors[key]);
    });
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveSale = async () => {
    if (!validateForm()) return;

    const formData = {
      userId,
      customerName,
      paymentMethod,
      products: tableData.map((item) => ({
        productId: item.item,
        price_vente: item.prix,
        quantity: item.quantite,
      })),
    };

    try {
      const response = await axios.post(
        `${server}/sells/api/ventes`,
        formData,
        { withCredentials: true }
      );
      if (response.data.success) {
        toast.success("Vente créée avec succès !");
        setTimeout(() => {
          window.location.replace("/ventes");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const calculateTotal = () => {
    return tableData.reduce((total, row) => total + row.prix * row.quantite, 0);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '38px',
      border: state.isFocused ? '1px solid #80bdff' : '1px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : null,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#007bff',
        color: 'white',
      },
    }),
  };

  const CustomPlaceholder = ({ children, ...props }) => {
    const { selectProps } = props;
    const selectedProduct = selectProps.value ? selectProps.value.label : null;
    const placeholder = selectedProduct || "--Select Product--";
    return (
      <components.Placeholder {...props}>
        {placeholder}
      </components.Placeholder>
    );
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header text-center">
          <h4>Vente</h4>
        </div>
        <div className="card-body" ref={printRef}>
          <div className="row">
            <div className="col-md-8">
              <div className="input-group mb-3">
                <span className="input-group-text">Nom de Client</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="client"
                  value={customerName}
                  onChange={handleChange(setCustomerName)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="input-group mb-3 input-group-date">
                <span className="input-group-text">Inv. Date</span>
                <input
                  type="date"
                  className="form-control"
                  value={invoiceDate}
                  onChange={handleChange(setInvoiceDate)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group mb-3">
                <span className="input-group-text">Methode Payaiment</span>
                <select
                  className="form-control"
                  value={paymentMethod}
                  onChange={handleChange(setPaymentMethod)}
                >
                  <option value="">
                    --Selectionner la methode de payment--
                  </option>

                  <option value="CASH">CASH</option>
                  <option value="KCB">KCB</option>
                  <option value="BANCOBU">BANCOBU</option>
                  <option value="IBB">IBB</option>
                  <option value="BCB">BCB</option>
                </select>
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom du produit</th>
                <th scope="col" className="text-end">Categorie</th>
                <th scope="col" className="text-end">Description</th>
                <th scope="col" className="text-end">Prix</th>
                <th scope="col" className="text-end">Quantite</th>
                <th scope="col">
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={handleAddRow}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={row.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <Select
                      className="custom-select"
                      classNamePrefix="react-select"
                      value={products.find(product => product._id === row.item) ? { value: row.item, label: products.find(product => product._id === row.item).name } : null}
                      onChange={handleProductSelection(index)}
                      options={products.map(product => ({ value: product._id, label: product.name }))}
                      placeholder={row.nom ? row.nom : "--Select Product--"}
                      components={{ Placeholder: CustomPlaceholder }} // Custom placeholder component
                      styles={customStyles}
                      menuPlacement="top"
                    />
                    {errors[`item${index}`] && (
                      <div className="text-danger">{errors[`item${index}`]}</div>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.categorie}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.description}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control text-end"
                      value={row.prix}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control text-end"
                      value={row.quantite}
                      onChange={handleRowChange(index, "quantite")}
                    />
                    {errors[`quantite${index}`] && (
                      <div className="text-danger">{errors[`quantite${index}`]}</div>
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeleteRow(index)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-8">
              <button
                type="button"
                className="btn btn-primary NoPrint"
                onClick={handleSaveSale}
              >
                Vendre
              </button>
            </div>
            <div className="col-md-4">
              <div className="input-group mb-3">
                <span className="input-group-text">Total</span>
                <input
                  type="number"
                  className="form-control text-end"
                  value={calculateTotal()}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
