import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { jsPDF } from "jspdf";

const RetraitDetails = () => {
  const { id } = useParams(); // Extract ID from URL
  const [Details, setDetails] = useState({});

  // Fetch payment details based on ID using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/retrait//api/retrait/${id}`
        );
        setDetails(response.data); // Assuming response structure
      } catch (error) {
        console.error("Error fetching payment details:", error);
        // Handle errors (e.g., display error message)
      }
    };

    fetchData();
  }, [id]);

  console.log(Details);

  if (!Details) {
    return <p>Loading payment details...</p>; // Display loading indicator
  }

  return (
    <div class="invoice-wrapper" id="print-area">
      <div class="invoice">
        <div class="invoice-container">
          <div class="invoice-head">
            <div class="invoice-head-top">
              <div class="invoice-head-top-left text-start">
                <img src={Logo} />
              </div>
              <div class="invoice-head-top-right text-end">
                <h3>Detaile des retraits</h3>
              </div>
            </div>
            <div class="hr"></div>
            <div class="invoice-head-middle">
              <div class="invoice-head-middle-left text-start">
                <p>
                  <span class="text-bold">Date</span>:{" "}
                  {Details.createdAt?.slice(0, 10)}
                </p>
              </div>
            </div>
            <div class="hr"></div>
            <div class="invoice-head-bottom">
              <div class="invoice-head-bottom-left">
                <ul>
                  <li class="text-bold">Bismos High Tech:</li>
                  <li> NIF: 400045190/RC:03436</li>
                  <li>Avenue: KIGARAMA, N0: 21</li>
                  <li>centre Fiscal:Ressort Nord </li>
                  <li>
                    Secteur d'activité: COM. General Forme Juridique: Personne
                    Morale
                  </li>
                </ul>
              </div>
              <div class="invoice-head-bottom-right">
                {/* <ul class="text-end">
                  <li class="text-bold">Pay To:</li>
                  <li>Koice Inc.</li>
                  <li>2705 N. Enterprise</li>
                  <li>Orange, CA 89438</li>
                  <li>contact@koiceinc.com</li>
                </ul> */}
              </div>
            </div>
          </div>
          <div class="overflow-view">
            <div class="invoice-body">
              <table>
                <thead>
                  <tr>
                    <td class="text-bold">Nom du produit</td>
                    <td class="text-bold">decription</td>
                    <td class="text-bold">Quantité retire</td>
                  </tr>
                </thead>
                <tbody>
                  {Details.products &&
                    Details.products.map((lineItem) => (
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 pl-0 color-black">
                          {lineItem.productName}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.desc}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.quantity}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div class="invoice-body-bottom">
                                <div class="invoice-body-info-item border-bottom">
                                    <div class="info-item-td text-end text-bold">Sub Total:</div>
                                    <div class="info-item-td text-end">$2150.00</div>
                                </div>
                                <div class="invoice-body-info-item border-bottom">
                                    <div class="info-item-td text-end text-bold">Tax:</div>
                                    <div class="info-item-td text-end">$215.00</div>
                                </div>
                                <div class="invoice-body-info-item">
                                    <div class="info-item-td text-end text-bold">Total:</div>
                                    <div class="info-item-td text-end">$21365.00</div>
                                </div>
                            </div> */}
            </div>
          </div>
          <div class="invoice-foot text-center">
            <p>
              <span class="text-bold text-end">NOTE:&nbsp;</span>This is
              computer generated receipt and does not require physical
              signature.
            </p>

            <div class="invoice-btns">
              <button type="button" class="invoice-btn">
                <span>
                  <i class="fa-solid fa-download"></i>
                </span>
                <span>Imprimer</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetraitDetails;
