import React from 'react'
import { AddBox } from "@mui/icons-material"; // Import AddBox for the create icon
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { Link } from 'react-router-dom';


const DataGridProductToolBar = ({ setOpenPopup, openPopup }) => {
    return (
        <FlexBetween>
          <GridToolbar />
          <Link to = "/recordStock">
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <AddBox /> Record du Stock
          </Button>
          </Link>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
            onClick={() => setOpenPopup(!openPopup)}
          >
            <AddBox /> Ajouter
          </Button>
        </FlexBetween>
      );
}

export default DataGridProductToolBar