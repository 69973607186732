import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ExternalRedirect = ({ url }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Open the URL in a new tab
    window.open(url, '_blank', 'noopener,noreferrer');

    // Optionally redirect back to a specific route or homepage
    navigate('/'); // Redirect to homepage or any other route
  }, [url, navigate]);

  return null;
};

export default ExternalRedirect;
