import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "../../src/server";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: server }),
  reducerPath: "adminApi",
  tagTypes: ["User", "Sales", "Users", "Prestation"],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/${id}`,
      providesTags: ["User"],
    }),
    getUsers: build.query({
      query: () => `user/`,
      providesTags: ["Users"],
    }),
    getSales: build.query({
      query: () => "sales/",
      providesTags: ["Sales"],
    }),
    getPrestations: build.query({
      query: () => "prestation/",
      providesTags: ["Prestation"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetSalesQuery,
  useGetPrestationsQuery,
  useGetUsersQuery,
} = api;
