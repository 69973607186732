import React, { useEffect, useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
  Close as CloseIcon,
  Person,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import FlexBetween from "./FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { backend_url } from "server";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import { useGetUserQuery } from "state/api";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [products, setProducts] = useState([]);
  const [hasLowStock, setHasLowStock] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isOpen = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const clearAllTokens = async () => {
    try {
      localStorage.removeItem("secretKeyesforzado7");
      toast.success("Logged out successfully");
      window.location.reload(true);
    } catch (error) {
      console.log("Error clearing all tokens: ", error);
      toast.error("An error occurred");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        const allProducts = response.data.allproducts;
        setProducts(allProducts);

        // Check if any product has stock less than 40
        const lowStock = allProducts.some((product) => product.stock < 40);
        setHasLowStock(lowStock);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const renderMenuItems = () => (
    <>
      {data?.role === "superadmin" && hasLowStock && (
        <MenuItem onClick={handleClose}>
          <Link to="/avertissement">
            <Alert severity="warning" />
          </Link>
        </MenuItem>
      )}
      <MenuItem onClick={handleClose}>
        <IconButton onClick={() => dispatch(setMode())}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined sx={{ fontSize: "25px" }} />
          ) : (
            <LightModeOutlined sx={{ fontSize: "25px" }} />
          )}
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link to="/settings">
          <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton>
        </Link>
      </MenuItem>
      {/* <MenuItem onClick={clearAllTokens}>Log Out</MenuItem> */}
    </>
  );

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <FlexBetween color={theme.palette.secondary.main}>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <Typography variant="h4" fontWeight="bold">
                  BISMOS: BUREAUTIQUE
                </Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        {!isMobile ? (
          <FlexBetween gap="1.5rem">
            {renderMenuItems()}
            <FlexBetween>
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={`${user?.profilepic?.url}`}
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Box textAlign="left">
                  <Typography
                    fontWeight="bold"
                    fontSize="0.85rem"
                    sx={{ color: theme.palette.secondary[100] }}
                  >
                    {user?.name}
                  </Typography>
                  <Typography
                    fontSize="0.75rem"
                    sx={{ color: theme.palette.secondary[200] }}
                  >
                    {user?.occupation}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={clearAllTokens}>Log Out</MenuItem>
              </Menu>
            </FlexBetween>
          </FlexBetween>
        ) : (
          <IconButton onClick={toggleDrawer}>
            <Box
              component="img"
              alt="profile"
              src={`${backend_url}/${user.profilepic}`}
              height="32px"
              width="32px"
              borderRadius="50%"
              sx={{ objectFit: "cover" }}
            />
          </IconButton>
        )}
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: 250,
            paddingTop: 2,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <List>{renderMenuItems()}</List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
