import { Box, Button, IconButton, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useEffect, useState } from "react";

const FormPaper = ({ openPopup, setOpenPopup }) => {
  const [namePaper, setNamePaper] = useState("");
  const [price, setPrice] = useState(0);
  const [id, setId] = useState("");

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const IdPapier = await localStorage.getItem("papierId");
      const res = await axios.put(`${server}/papier/add-papier/${IdPapier}`, {
        namePaper,
        price,
      });
      console.log(res.data);
      window.location.replace("/papier");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header
          title="AJOUTER UN PAPIER"
          subtitle="Ajouter un nouveau type de papier"
        />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <Formik

      // initialValues={initialValues}
      // validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Nom"
                onBlur={handleBlur}
                value={namePaper}
                onChange={(e) => setNamePaper(e.target.value)}
                name="Nom"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="interieur"
                onBlur={handleBlur}
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                name="interieur"
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Ajouter un type de papier
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  Nom: yup.string().required("required"),

  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  MontantReel: yup.string().required("required"),
  total: yup.string().required("required"),
});
const initialValues = {
  Nom: "",
  interieur: "",
  Couverture: "",
  rabatintercalaire: "",
  total: "",
  MontantReel: "",
};

export default FormPaper;
