import { Box, Button, IconButton, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { PriceChange, Try } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormMainCommand = ({ openPopup, setOpenPopup }) => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [nomClient, setNomClient] = useState("");
  const [contact, setContact] = useState("");
  const [prestations, setPrestations] = useState({
    commande: "",
    qty: 0,
    price_unitaire: 0,
    price_total: 0,
    avance: 0,
    reste: 0,
    desc: " ",
    relation: " ",
  });

  useEffect(() => {
    // Calculate finalPrice whenever qty or priceTTC changes
    setPrestations((prevState) => ({
      ...prevState,
      price_total: Number(prestations.qty) * Number(prestations.price_unitaire),
    }));
  }, [prestations.qty, prestations.price_unitaire]);

  useEffect(() => {
    // Calculate finalPrice whenever qty or priceTTC changes
    setPrestations((prevState) => ({
      ...prevState,
      reste: Number(prestations.price_total) - Number(prestations.avance),
    }));
  }, [prestations.price_total, prestations.avance]);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${server}/commande/create-commande/`, {
        userId,
        userName: data.name,
        nomClient,
        contact,
        prestations,
      });
      // console.log(userId, nomClient, contact, prestations);
      window.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="CREATION D'UNE COMMANDE" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <Formik

      // initialValues={initialValues}
      // validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Nom du client"
                onBlur={handleBlur}
                value={nomClient}
                onChange={(e) => setNomClient(e.target.value)}
                name="Nom du client"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Numero du client"
                onBlur={handleBlur}
                onChange={(e) => setContact(e.target.value)}
                value={contact}
                name="Numero du client"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="La commande"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({ ...prestations, commande: e.target.value })
                }
                name="La commande"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="La quantité"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({
                    ...prestations,
                    qty: parseInt(e.target.value),
                  })
                }
                name="La quantité"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Prix unitaire"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({
                    ...prestations,
                    price_unitaire: parseInt(e.target.value),
                  })
                }
                name="Prix unitaire"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                readOnly
                type="number"
                label={`Prix total (PT) : ${prestations.price_total} `}
                value={prestations.price_total}
                name="Prix total (PT)"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Avance"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({
                    ...prestations,
                    avance: parseInt(e.target.value),
                  })
                }
                name="Avance"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                readOnly
                type="number"
                label={`le reste  : ${prestations.reste} `}
                value={prestations.reste}
                onChange={(e) =>
                  setPrestations({
                    ...prestations,
                    reste: parseInt(e.target.value),
                  })
                }
                name="le reste"
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Observation"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({ ...prestations, desc: e.target.value })
                }
                name="Observation"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="relation"
                onBlur={handleBlur}
                onChange={(e) =>
                  setPrestations({ ...prestations, relation: e.target.value })
                }
                name="relation"
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Créer une commande
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  Nom: yup.string().required("required"),

  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  MontantReel: yup.string().required("required"),
  total: yup.string().required("required"),
});
const initialValues = {
  Nom: "",
  interieur: "",
  Couverture: "",
  rabatintercalaire: "",
  total: "",
  MontantReel: "",
};

export default FormMainCommand;
