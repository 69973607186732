import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Les Questions souvent demandées" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            1. Quels sont les droits du superadmin?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Le superadmin a accès à tous les modules et peut effectuer des
            modifications. Seul le superadmin peut changer les données des
            employés.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            2. Quels sont les accès de l'admin?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            L'admin a accès à tous les modules concernant le paiement et le
            crédit des employés. Il peut établir des devis et des factures
            finales, mais seul le superadmin peut modifier les prix. L'admin
            peut également vérifier le module des achats.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            3. Quels sont les accès du caissier?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Le caissier a accès aux commandes, aux dépenses et aux ventes.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            4. Quels sont les accès du gestionnaire?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Le gestionnaire a accès aux stocks et peut ajouter de nouveaux
            produits.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            5. Comment effectuer une vente?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Pour effectuer une vente, allez dans l'onglet "Vente", entrez le nom
            du client, sélectionnez les produits que le client souhaite acheter,
            et ajoutez-les en cliquant sur "+" ou "-" pour ajuster les quantités
            des produits.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            6. Que faire quand vous n'avez pas accès à un module quelconque?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Il faut écrire au directeur général pour demander l'accès.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Some Random Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            7. Comment changer son mot de passe?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Pour changer son mot de passe, allez dans les paramètres et cliquez
            sur "Changer mon mot de passe".
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            8. Comment se déconnecter?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Pour se déconnecter, allez dans les paramètres et cliquez sur "Se
            déconnecter".
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
