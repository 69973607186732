import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userId = useSelector((state) => state.global.userId);
  if (!userId) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoute;
