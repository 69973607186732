import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'


const PopupConfirm = (props) => {
    const {title , children, open,setOpen} = props

    return (
      <div>
          <Dialog open ={open}>
              <DialogTitle>
                  {title}
              </DialogTitle>
              <DialogContent>
                  {children}
              </DialogContent>
          </Dialog>
      </div>
    )
}

export default PopupConfirm