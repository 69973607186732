import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Popup from "components/Popup";
import { useParams } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import FormPrestation from "scenes/formPrestation";
import axios from "axios";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";
import FormAddDepot from "scenes/formAddDepot";

const Depot = () => {
  const theme = useTheme();
  const { id } = useParams();

  const [depositTransactions, setDepositTransactions] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/deposit/deposit-transactions`
        );
        setDepositTransactions(response.data.depositTransactions || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async (depositTransactionId) => {
    try {
      if (window.confirm("Veux-tu supprimer cette transaction de dépôt?")) {
        const response = await axios.delete(
          `${server}/deposit/delete-deposit-transaction/${depositTransactionId}`
        );
        if (response.status === 200) {
          toast.success("Transaction de dépôt supprimée avec succès.");
          window.location.reload(true);
        } else {
          toast.error("Échec de la suppression de la transaction de dépôt.");
        }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Erreur de suppression de la transaction de dépôt."
      );
      console.error("Error deleting deposit transaction:", error);
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Fait le",
      flex: 2,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 2,
    },
    {
      field: "depositAmount",
      headerName: "La somme déposer",
      flex: 2,
    },
    {
      field: "bankAccount",
      headerName: "Bank",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Les dépots d'argent dans la banque" />
      </FlexBetween>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={depositTransactions.map((transaction) => ({
            id: transaction._id,
            nomClient: transaction.nomClient,
            depositAmount: transaction.depositAmount,
            userName: transaction.userName,
            bankAccount: transaction.bankAccount,
            date: transaction.date?.slice(0, 10),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Ajouter un autre dépot"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormAddDepot setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default Depot;
