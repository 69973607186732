import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LoginRoute = ({ children }) => {
  const userId = useSelector((state) => state.global.userId);
  if (userId) {
    return <Navigate to="/acceuil" replace />;
  }

  return children;
};

export default LoginRoute;
