import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { Link, useParams } from "react-router-dom";
import "./user.css";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { backend_url, server } from "../../server";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toast } from "react-toastify";

export default function EditTeamMember() {
  const theme = useTheme();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    profilepic: "",
    phoneNumber: "",
    cni: "",
    occupation: "",
    role: "",
    password: "",
  });

  useEffect(() => {
    const fetchPrestation = async () => {
      try {
        const response = await axios.get(`${server}/user/${id}`);
        setUser(response.data);
        setFormData({
          name: response.data.name,
          email: response.data.email,
          profilepic: response.data.profilepic,
          phoneNumber: response.data.phoneNumber,
          cni: response.data.cni,
          occupation: response.data.occupation,
          role: response.data.role,
        });
      } catch (error) {
        setError("Error fetching users");
      } finally {
        setLoading(false);
      }
    };

    fetchPrestation();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${server}/user/update-user-info/${id}`,
        formData
      );
      toast.success("user updated successful");
      setTimeout(() => {
        window.location.replace("/admin");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: isMobile ? 2 : 5,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2, textAlign: "center" }}>
        Editer l'Utilisateur: <strong>{user?.name}</strong>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 4,
          justifyContent: "center",
          marginTop: 4,
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <img
              src={`${user?.profilepic?.url}`}
              alt="Profile"
              className="userShowImg"
              style={{
                width: isMobile ? "150px" : "200px",
                height: isMobile ? "150px" : "200px",
                borderRadius: "10px",
              }}
            />
            <Typography variant="h5">{user?.name}</Typography>
            <Typography variant="body1">{user?.occupation}</Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6">Les details de l'utilisateur</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PermIdentity />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarToday />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user.occupation}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Contact de l'utilisateur
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneAndroid />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user?.phoneNumber}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MailOutline />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user?.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationSearching />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  NGOZI | BURUNDI
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 2 }}>
          <Typography
            variant="h6"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            Editer
          </Typography>
          <form
            className="userUpdateForm"
            onSubmit={handleUpdate}
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <TextField
              fullWidth
              variant="filled"
              label="Nom complet"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              readOnly
            />
            <TextField
              fullWidth
              variant="filled"
              label="Occupation"
              value={formData.occupation}
              onChange={(e) =>
                setFormData({ ...formData, occupation: e.target.value })
              }
              readOnly
            />
            <TextField
              select
              fullWidth
              variant="filled"
              label="Role"
              value={formData.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
              readOnly
            >
              <MenuItem value="superadmin">superadmin</MenuItem>
              <MenuItem value="admin">admin</MenuItem>
              <MenuItem value="gestionnaire">gestionnaire</MenuItem>
              <MenuItem value="caissiere">caissiere</MenuItem>
            </TextField>
            <TextField
              fullWidth
              variant="filled"
              label="E-mail"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              readOnly
            />
            <TextField
              fullWidth
              variant="filled"
              label="Numero de téléphone"
              value={formData.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
              readOnly
            />
            <TextField
              fullWidth
              variant="filled"
              label="CNI ou ID"
              value={formData.cni}
              onChange={(e) =>
                setFormData({ ...formData, cni: e.target.value })
              }
              readOnly
            />
            <TextField
              fullWidth
              variant="filled"
              label="Password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              readOnly
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <img
                src={`${user?.profilepic?.url}`}
                alt="Profile"
                className="userUpdateImg"
                style={{
                  width: isMobile ? "150px" : "200px",
                  height: isMobile ? "150px" : "200px",
                  borderRadius: "10px",
                }}
              />
              <label htmlFor="file">
                <Publish className="userUpdateIcon" />
              </label>
              <input type="file" id="file" style={{ display: "none" }} />
            </Box>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ marginTop: 2 }}
            >
              Update
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
